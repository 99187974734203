import React, {Component} from 'react'
import Base from '../BaseComponent'
import conf from '../../_conf';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";

//import settings from '../../data/settings';

// global map styles
const defaultStyle = require("./styles.json");

// Map component with Script tag and API (just need one, so defined outside of render)
const MyMapComponent = withScriptjs(withGoogleMap((props) => 
		  <GoogleMap
		  	// Callback on mount
		  	ref={(ref) => {
		  		if(ref){
		  			props.onMount(ref);
		  		}
		  	}}
		    defaultZoom={props.zoom}
		    defaultCenter={props.center}
		    defaultOptions={{ scrollwheel:props.scrollWheel, styles: props.mapStyles, disableDefaultUI: !props.defaultUI}}>
		    	{props.markers}
		    </GoogleMap>
		))


class MiniMap extends Component {

	MOUNTED = false;
	REF = null;
	MARKERS = [];
	MARKERBOUNDS = [];

	// Callback with google maps API
	onMount = (ref) => {
		if(!this.MOUNTED){
			this.MOUNTED = true;
			this.REF = ref;
			this.fitBounds();
		}
	}

	// Update new markers and fit bounds on update
	componentWillReceiveProps = (nextProps) =>
    {

    	let boundsWere = [...this.MARKERBOUNDS];
    	this.setMarkers(nextProps);
    	if(JSON.stringify(boundsWere) != JSON.stringify(this.MARKERBOUNDS)) this.fitBounds();


    }

    // Loops through markers and fit the bounds
    fitBounds = () => {

    	if(!this.props.fitBounds) return null;
    	if(!this.REF) return null;

    	let bounds = new window.google.maps.LatLngBounds();

          this.MARKERBOUNDS.map(marker => {
          	//console.log(marker);
            if(marker.lat != '') {
              const ll = new window.google.maps.LatLng(marker.lat, marker.lng);
              bounds.extend(ll)
            }
          });

          if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
		       var extendPoint1 = new window.google.maps.LatLng(bounds.getNorthEast().lat() + 0.002, bounds.getNorthEast().lng() + 0.002);
		       var extendPoint2 = new window.google.maps.LatLng(bounds.getNorthEast().lat() - 0.002, bounds.getNorthEast().lng() - 0.002);
		       bounds.extend(extendPoint1);
		       bounds.extend(extendPoint2);
		    }

          this.REF.fitBounds(bounds);
        


    }

    // Update logic...
	shouldComponentUpdate (nextProps) {

		return true;
		//return (this.props.key != nextProps.key);

	}

	// Set components MARKERS and MARKERBOUNDS
	setMarkers = (props) => {

		

		let markers = props.markers || [{
	  		lat : props.lat,
	  		lng : props.lng
	  	}];

	  
	  // Can send in different array of lat/lnh props for bounds to be different to the markers
  	let boundsmarkers = props.boundsmarkers || markers;

  	let markerBounds = boundsmarkers.map(m =>(

  		{
			lat: parseFloat(''+m.lat),
			lng: parseFloat(''+m.lng)
		}


  	));

  		this.MARKERS = markers;
		this.MARKERBOUNDS = markerBounds;

	}

	
	// Render the google map
  render () {

  	//console.log('MAP',this.props);
  	if(!this.MARKERS.length) this.setMarkers(this.props);

  	let settings = conf.get();
  	let marker = (this.props.markerColour != "") ? this.props.markerColour : '#000000'
  	// SVG marker icon
  	let icon = {
	    path: "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0Z",
	    fillColor: marker,
	    fillOpacity: 1,
	    strokeWeight: 0,
	    scale: 1
	}

	/*
		Size:
		size: {width: 60, height: 100}
		
	*/

	if(this.props.icon) icon = {...icon,...this.props.icon};

	const height = this.props.height || '100%';
	const scrollWheel = this.props.scrollWheel || false;
	let zoom = this.props.zoom || 15;
	
	const defaultUI = this.props.hasOwnProperty('defaultUI') ? this.props.defaultUI : true;


	//position object from props
	const latlng = {
		lat: parseFloat(''+this.props.lat),
		lng: parseFloat(''+this.props.lng)
	}

	const center = (this.props.centerlat) ? {
		lat: parseFloat(''+this.props.centerlat),
		lng: parseFloat(''+this.props.centerlng)
	} : latlng;

	const mapStyles = this.props.styles || defaultStyle;
  	

	let markerLabels = this.MARKERS.map((m,ix)=>{
		    		let markerLatLng = {
						lat: parseFloat(m.lat),
						lng: parseFloat(m.lng)
					}

					let props = {icon:icon,position:markerLatLng,...m};

					if(!m.content && !m.onClick) return (<Marker {...props} />)

					let modifier = m.modifier || 'default';
						
					return (
					<MarkerWithLabel
						key={`marker${ix}`}
					      position={props.position}
					      icon={{
						    path: "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0Z",
						    fillColor: '#000000',
						    fillOpacity: 0,
						    strokeWeight: 0,
						    scale: 0
						}}
					      labelAnchor={{x:0,y:0}}
					      labelStyle={null}
					      labelClass={'Map-marker Map-marker--'+modifier}
					      onClick={props.onClick || null}
					      onMouseOver={props.onMouseOver || null}
					      onMouseEnter={props.onMouseEnter || null}
					      onMouseOut={props.onMouseOut || null}
					    >
					      <div className="Map-marker-content">{m.content}</div>
					 </MarkerWithLabel>);
					})	
		    	
			  
  	let passProps = {...this.props};
  	if(passProps.markerColour) delete passProps.markerColour;
  	if(passProps.styles) delete passProps.styles;
  	if(passProps.boundsmarkers) delete passProps.boundsmarkers;
  	if(passProps.markers) delete passProps.markers;
  	if(passProps.hasOwnProperty('defaultUI')) delete passProps.defaultUI;
  	if(passProps.fitBounds) delete passProps.fitBounds;
  	if(passProps.hasOwnProperty('onMount')) delete passProps.onMount;
  	if(passProps.hasOwnProperty('zoom')) delete passProps.zoom;
  	if(passProps.hasOwnProperty('lat')) delete passProps.lat;
  	if(passProps.hasOwnProperty('lng')) delete passProps.lng;

    return (

      <Base {...passProps} className={this.props.className || "MiniMap"}>

      	<MyMapComponent
      		zoom={zoom}
      		markerBounds={this.MARKERBOUNDS}
      		onMount={this.onMount}
      		markers={
      				markerLabels
      			}
      		defaultUI={defaultUI}
      		center={center}
    		mapStyles={mapStyles}
  			scrollWheel={scrollWheel}
			isMarkerShown
			googleMapURL={"https://maps.googleapis.com/maps/api/js?key="+settings.GOOGLE_MAPS_API_KEY+"&v=3.exp&libraries=geometry,drawing,places"}
			loadingElement={<div style={{ width: '100%', height: `100%` }} />}
			containerElement={<div style={{ width: '100%', height: height }} />}
			mapElement={<div style={{ width: '100%', height: `100%` }} />}
		/>
        
      </Base>
    )
  }
}

export default MiniMap
