import React, { Component } from 'react';

import PageElement from '../_core/components/PageElement';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import SiteModifier from '../_core/components/SiteModifier';
import MousePos from '../_core/components/MousePos';
import ToolTip from '../_core/components/ToolTip';

import {withStore} from '../_core/utils';

import { withRouter } from "react-router-dom";

// Models
import {Asset,Entry,Data} from '../_core/models';

// Project
import * as Project from '../components';


class Basic extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	

	/*

		RENDER

	*/

	render() {

		return (
			<div >
				<SiteModifier auto demodifier={'clients,flip,hover,navhover'} />
			</div>
		)

	}
}

export default withRouter(withStore(Basic));
