import React, { Component } from 'react';

//import {PageElement,Template,SiteModifier,Content} from '../_core/components';

import PageElement from '../_core/components/PageElement';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import SiteModifier from '../_core/components/SiteModifier';
import FadeIn from '../_core/components/FadeIn';
import Media from '../_core/components/Media';
import Link from '../_core/components/Link';
import Html from '../_core/components/Html';

// Models
import {Asset,Entry,Data,SetData} from '../_core/models';

// Project
import * as Project from '../components';



class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;
		if(!entry) return null;

		let blocks = entry.blocks || [];


		let isHome = entry.uri == '/';

	
	
		return (

				<Template modifiers={[entry.type,entry.template]} title={entry.title}>
				
				{/* Reset styles */}
				<SiteModifier auto demodifier={'scrolled,gridscrolled,caption'} />
				{(()=>{

	                if(isHome){
	                  return (<SiteModifier auto modifier={'home,projects'} />);
	                }else{
	                	return (<SiteModifier auto demodifier={'home,projects'} />);
	                }

	              })()}

				{/* Template */}
		
					{/* Title */}
					<div className="Template-head">
						<div className="Container">
							<h1>{ entry.title }</h1>
						</div>
					</div>

					

				
					<Link to={window.location.pathname+'#/'} className="Template-close" />
			
					
					<div className="Template-detail">
						
						<Data require={['entries']}>
      					{({entries})=>{

							let active = window.location.hash.replace('#','').replace(/\//gi,'');
							let activeTeam = entries.find((t)=>t.slug == active);

						
							if(activeTeam){
								return (
								<FadeIn key={activeTeam.slug}>
								<SiteModifier modifier="detail" auto />
								<div className="Template-nav">
								<ul className="List List--sub">
                                
                                    <li><Link to={window.location.pathname+'#/'}>Close</Link></li>
                                
                              	</ul>
                              	</div>
								<div className="Item--bio" key={activeTeam.slug}>
									
									<div className="Item-media">
										<Media id={activeTeam.featureImage} fluid />
									</div>
									<div className="Item-body">
										<Content modifier="bio">
											<h1>{activeTeam.title},<br />{activeTeam.teamRole}</h1>
											<Html html={activeTeam.content} />
											{(()=>{
												if(activeTeam.teamQualifications.length){
													return (<h3>Qualifications</h3>)
												}
											})()}
											<dl className="Lit List--qualifications">
												{
													activeTeam.teamQualifications && activeTeam.teamQualifications.map(
														(q) => (
															<li>
																<dt>{q.text}</dt>
																<dd>{q.note}</dd>
															</li>
														)
													)
												}
											</dl>
										</Content>
									</div>
								</div>
								</FadeIn>
								)
							}else{
								return <SiteModifier demodifier="detail" auto />;
							}
						}}
						</Data>
					
					</div>

					{/* Body */}


	                      	<div className="Template-body">
	                      		<div className="Template-nav">
	                      		<Data require={['structures']}>
      {({structures,ENTRY,entries,assets})=>{
          return (
              <ul className="List List--sub">
                                {structures.team.map(((item,ix)=>(
                                    <li key={'nav_'+ix}><Link exact navlink autotarget matchAgainst={window.location.pathname} matchUri={item.uri} to={item.uri}>{item.title}</Link>
                                    
                                    </li>
                                )))}
                              </ul>
                )}}
                </Data>
                </div>
                				<FadeIn>
								<div className="Grid Grid--team">
									
									<Entry id={entry.related_people} render={
										(item) => {

											let to = (window.location.hash.match(item.slug)) ? window.location.pathname : window.location.pathname+'#/'+item.slug+'/';


											return (
											<div>
												<Link to={to} navlink={((window.location.hash != '') && (window.location.hash != '#/') )} className={"Item Item--team"}>
												
													<div className="Item-media" key={item.featureImage}>
														<Media id={item.featureImage} fluid />
													</div>
													<div className="Item-body">
														{item.title}<br />
														<span className="Item-role">{item.teamRole}</span>
													</div>
												</Link>
											</div>
											)
										}

									} />
									
								</div>
								</FadeIn>
							</div>


	                     
					
				<FadeIn>
					{/* Blocks */}
					<div className="Template-blocks">
						<div className="Container">
							<Project.Blocks blocks={[...blocks]} entry={entry} modifiers={['debug']} />
						</div>
					</div>
				</FadeIn>
				</Template>
		)

	}
}

export default Default;
