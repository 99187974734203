import React, { Component } from 'react';

//import {PageElement,Template,SiteModifier,Content} from '../_core/components';

import PageElement from '../_core/components/PageElement';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import SiteModifier from '../_core/components/SiteModifier';
import FadeIn from '../_core/components/FadeIn';

// Models
import {Asset,Entry,Data,SetData} from '../_core/models';

// Project
import * as Project from '../components';



class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		

	
		return (
			
				<Template modifiers={['page','blocks','404']} title={'Page Not Found'}>
				<FadeIn>
				{/* Reset styles */}
				<SiteModifier auto demodifier={'scrolled,gridscrolled,caption'} />
				<SiteModifier auto demodifier={'home,projects'} />

				{/* Template */}
		
					{/* Title */}
					<div className="Template-head">
						<div className="Container">
							<h1>Page Not Found</h1>
						</div>
					</div>

					

	                      	<div className="Template-intro">
								
									<Content modifier={'intro'}>
										<p>Page not found.<br />Sorry, we can't seem to find that page. We have updated our website, so the page you were looking for may have been moved.<br /><br />If you feel there should be a page here, please <a href="/contact/">contact us</a>. Otherwise, please use the navigation above to browse our site, or visit our <a href="/">home page</a>.</p>
									</Content>
								
							</div>

	             
					</FadeIn>
				</Template>
		)

	}
}

export default Default;
