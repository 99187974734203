import React, { Component } from "react";

//import {PageElement,Template,SiteModifier,Content} from '../_core/components';

import PageElement from "../_core/components/PageElement";
import Template from "../_core/components/Template";
import Content from "../_core/components/Content";
import SiteModifier from "../_core/components/SiteModifier";
import Map from "../_core/components/Map";
import FadeIn from "../_core/components/FadeIn";

// Models
import { Asset, Entry, Data, SetData } from "../_core/models";

// Project
import * as Project from "../components";

import conf from "../_core/_conf";

class Default extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  /*

		RENDER

	*/

  render() {
    let { entry } = this.props;
    if (!entry) return null;

    let blocks = entry.blocks || [];

    console.log("ENTRY", entry);

    let settings = conf.get();
    let isHome = entry.uri == "/";

    return (
      <Template
        modifiers={[entry.type, entry.template, "contact"]}
        title={entry.title}>
        <FadeIn>
          {/* Reset styles */}
          <SiteModifier auto demodifier={"scrolled,gridscrolled,caption"} />
          {(() => {
            if (isHome) {
              return <SiteModifier auto modifier={"home,projects"} />;
            } else {
              return <SiteModifier auto demodifier={"home,projects"} />;
            }
          })()}
          {/* Template */}
          {/* Title */}
          <div className="Template-head">
            <div className="Container">
              <h1>{entry.title}</h1>
            </div>
          </div>
          {/* Body */}
          {(() => {
            if (entry.content && entry.content.trim() != "<p></p>") {
              return (
                <div className="Template-body">
                  <Content modifier={"basic"} html={entry.content} />
                </div>
              );
            }
          })()}
          <div className="Template-map">
            <Map
              lat={"51.52068"}
              lng={"-0.1369097"}
              markers={[
                {
                  lat: "51.52068",
                  lng: "-0.136909",
                  onClick: () =>
                    window.open("https://goo.gl/maps/7mys7KADdnveQPip9"),
                },
              ]}
              zoom={15}
              centerlng={"51.5206"}
              markerColour={"#000000"}
              styles={settings.MAPSTYLES}
              defaultUI={true}
            />
          </div>
          {/* Blocks */}
          <div className="Template-blocks">
            <div className="Container">
              <Project.Blocks
                blocks={[...blocks]}
                entry={entry}
                modifiers={["debug"]}
              />
            </div>
          </div>
        </FadeIn>
      </Template>
    );
  }
}

export default Default;
