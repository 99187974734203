import React, {Component} from 'react'
import SiteModifier from '../../_core/components/SiteModifier';

let TimelineMax = window.TimelineMax;
let Power0 = window.Power0;


class Logo extends Component {

  widths = {
    de:0,
    metz:0,
    forbes:0,
    knight:0
  }

  tl=false;

  constructor() {
    super()

  }

  componentDidMount = () => {

    if(!this.refs['de']) return;

      this.widths.de = this.refs['de'].offsetWidth;
      this.widths.metz = this.refs['metz'].offsetWidth;
      this.widths.forbes = this.refs['forbes'].offsetWidth;
      this.widths.knight = this.refs['knight'].offsetWidth;

      let tl = new TimelineMax({paused:true});
      

      tl.to(this.refs['de'],0.2,{opacity:0,ease:Power0.easeNone},0);
      tl.to(this.refs['metz'],0.2,{opacity:0,ease:Power0.easeNone},0);
      tl.to(this.refs['forbes'],0.2,{opacity:0,ease:Power0.easeNone},0);
      tl.to(this.refs['knight'],0.2,{opacity:0,ease:Power0.easeNone},0);
      tl.to(this.refs['de'],0.6,{width:0,ease:Power0.easeNone},0);
      tl.to(this.refs['metz'],0.6,{width:0,ease:Power0.easeNone},0);
      tl.to(this.refs['forbes'],0.6,{width:0,ease:Power0.easeNone},0);
      tl.to(this.refs['knight'],0,{width:0,ease:Power0.easeNone},0);


      this.tl = tl;

  }

  componentWillReceiveProps = (props) => {

    if(!this.tl || !props.hasOwnProperty('progress')) return;

    this.tl.progress(props.progress);

  }
  render () {

    return (

      
     
        <h1 className="Logo--anim">d<span className="Logo-de" ref="de">e</span>M<span className="Logo-metz" ref="metz">etz</span>F<span className="Logo-forbes" ref="forbes">orbes</span>K<span className="Logo-knight" ref="knight">night</span></h1>

    );

  }
}

export default Logo
