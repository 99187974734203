import React, {Component} from 'react'
import { Data } from '../../_core/models'
import { DataRouter } from '../../_core/controllers'

import Layout from '../../templates/_layout';

class Router extends Component {

  constructor() {
    super()

  }

  

  render () {
      return (
      <DataRouter routes={[
          // Store an ENTRY and HOME global for any URI
          {
            route:'(/.*)/?',
            data : (matches,data)=> {

              let found = false;
              let home = false;

              found = data.entries.find(entry=>(entry.uri == window.location.pathname));
              home = data.entries.find(entry=>(entry.uri == '/'));


              
              return {ENTRY:found,HOME:home,lightbox:false,showMore:false,infoActive:false,SLIDESHOW:false}

            }
          },
          /*{
            route:'^/$',
            data : (matches,data)=> {

              
              let SLIDESHOW = (data.hasOwnProperty('SLIDESHOW')) ? !data.SLIDESHOW : false;
              if(!data.hasOwnProperty('introComplete')) SLIDESHOW = false;

              return {SLIDESHOW:SLIDESHOW}

            }
          },*/
          // Set a TEMPLATE variable based on a hash value
          // eg /projects/#/project-slug/3/details/
          {
            route:'details',
            data : (matches,data)=> {
              
              return {infoActive:true}

            }
          },
          {
            route:'drawings',
            data : (matches,data)=> {
              
              return {enlargeIx:1,lightbox:true}

            }
          }
          ]}>
          <Data require={['HOME']}>
              {()=>(

                  <Layout />
              )}
          </Data>
      </DataRouter>
      )
  }
}

export default Router
