import React, { Component } from "react";

// Conf
import conf from "./_core/_conf";

// Core
import Loader from "./_core/components/Loader";
import Media from "./_core/components/Media";
import Site from "./_core/components/Site";
import Burger from "./_core/components/Burger";
import Content from "./_core/components/Content";
import Image from "./_core/components/Image";
import SiteModifier from "./_core/components/SiteModifier";
import ViewportProgress from "./_core/components/ViewportProgress";
import FadeIn from "./_core/components/FadeIn";
import Link from "./_core/components/Link";
import Gallery from "./_core/components/Gallery";
import Transition from "./_core/components/Transition";

import { Entry, Data } from "./_core/models";
import { withStore } from "./_core/utils";

// Layout
import Layout from "./templates/_layout";

// Project
import * as Project from "./components";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { progress: 0 };
  }

  componentWillMount = () => {
    const _this = this;
  };

  render() {
    // Load the settings to get the manifest (JSON data to power the site)
    let settings = conf.get();
    let _this = this;

    return (
      <Site>
        <Loader
          manifest={[...settings.MANIFEST]}
          progress={() => {
            return null;
          }}
          hold={0}
          loaded={({ entries }) => {
            return (
              <Data require={["entries", "structures"]}>
                {({ entries, structures }) => {
                  let HOME = entries.find((e) => e.uri == "/");

                  return (
                    <FadeIn duration={0.3}>
                      <SiteModifier modifier="loadComplete" auto delay={0} />
                      <SiteModifier modifier="ready" auto delay={0} />

                      <div className="Site-intro">
                        <Project.Intro images={HOME.splashImages} />
                      </div>

                      <SiteModifier toggle="menu">
                        <div className="Site-burger" />
                      </SiteModifier>

                      <div className="Site-menu">
                        <Data require={["structures"]}>
                          {({ structures, ENTRY, entries, assets }) => {
                            return (
                              <SiteModifier
                                demodifier="menu"
                                delay={250}
                                forceDelay={true}>
                                <ul className="List List--menu">
                                  {structures.main.map((item, ix) => (
                                    <li key={"nav_" + ix}>
                                      <Link
                                        navlink
                                        autotarget
                                        matchUri={item.uri}
                                        to={
                                          item.children.length
                                            ? item.children[0].uri
                                            : item.uri
                                        }>
                                        {item.title}
                                      </Link>
                                      <ul>
                                        {item.children.map((child, cx) => (
                                          <li key={"nav_child_" + cx}>
                                            <Link
                                              navlink
                                              autotarget
                                              to={child.uri}>
                                              {child.title}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </li>
                                  ))}
                                </ul>
                              </SiteModifier>
                            );
                          }}
                        </Data>

                        <div className="Site-menu-contact">
                          <Content modifier="footer">
                            <p>
                              76 Charlotte Street, <br />
                              London,
                              <a href="https://goo.gl/maps/7mys7KADdnveQPip9">
                                W1T 4QS
                              </a>
                            </p>
                          </Content>

                          <Content modifier="footer">
                            <p>T: +44(0)20 7435 1144</p>
                            <p>
                              E:{" "}
                              <a href="mailto:info@dmfk.co.uk">
                                info@dmfk.co.uk
                              </a>
                            </p>
                          </Content>
                        </div>
                      </div>

                      <SiteModifier demodifier="menu">
                        <div className="Site-menu-close" />
                      </SiteModifier>
                      <div className="Site-load">
                        <Project.Router />
                      </div>
                    </FadeIn>
                  );
                }}
              </Data>
            );
          }}
        />
      </Site>
    );
  }
}

export default withStore(Index);
