import React, { Component } from 'react';

// Conf
import conf from '../../_core/_conf';

// Core
import Link from '../../_core/components/Link';
import Loader from '../../_core/components/Loader';
import Media from '../../_core/components/Media';
import Site from '../../_core/components/Site';
import Burger from '../../_core/components/Burger';
import Content from '../../_core/components/Content';
import Image from '../../_core/components/Image';
import SiteModifier from '../../_core/components/SiteModifier';
import ViewportProgress from '../../_core/components/ViewportProgress';
import Gallery from '../../_core/components/Gallery';

import {Data} from '../../_core/models';
import {withStore} from '../../_core/utils';

// Project
import * as Project from '../../components';

let TweenLite = window.TweenLite;

class Intro extends Component {

  complete=0;
  mediaLocked=1;
  logoLocked=1;
  images = [];

  constructor(props){

      super(props);
      this.state = {navActive:0,complete:0,logoProgress:0,progress:0,color:'white'};

      this.images = this.props.images.shuffle();
  }

  componentWillMount = () => {

      const _this = this;

  }

  update = (data) => {

      if(this.complete) return;

      let progress = data.progress;
      let travelled = data.travelled;
      let dist = data.dist;

      if(progress >= 1){

        this.doComplete();
        
        return;
      }

      let moveMediaAt = this.refs['logoSpacer'].offsetHeight;

      // Move media
      if(travelled >  moveMediaAt){
          if(this.mediaLocked){

            let mediaProgress = Math.min(1,(progress - 0.5) * 2);
            //TweenLite.set(this.refs['media'],{position:'absolute',y:-(window.innerHeight * mediaProgress)+'px'})
            TweenLite.set(this.refs['media'],{position:'absolute',y:(moveMediaAt)+'px'})
            this.mediaLocked=0;
          }

          //let prog = Math.max(progress,0.75);
          let opacity = 1 - ((travelled - moveMediaAt) / (dist - moveMediaAt));
          
          //let opacity = 1 - ((progress * 0.5));

          //if(opacity >= 0.8) opacity = 0.8;
          TweenLite.set(this.refs['background'],{opacity:opacity});
          
      }else{
          if(!this.mediaLocked){

            TweenLite.set(this.refs['media'],{position:'fixed',y:0});
            TweenLite.set(this.refs['background'],{opacity:1});
            this.mediaLocked=1;
          }
          
      }

      // Move logo
      let moveLogoAt = dist * 0.75;
      moveLogoAt = this.refs['mediaSpacer'].offsetHeight;
      let logoHeight = this.refs['logotext'].offsetHeight;

      moveLogoAt += logoHeight / 2;
      moveLogoAt += 20;

      //moveLogoAt -= logoHeight / 2;
      //moveLogoAt -= 20;

      if(travelled >  moveLogoAt){
          if(this.logoLocked){

            TweenLite.set(this.refs['logo'],{position:'absolute',y:moveLogoAt+'px'})
            this.logoLocked=0;
          }
          //let logoMove = Math.abs(travelled - moveLogoAt);
          
      }else{
          if(!this.logoLocked){

            TweenLite.set(this.refs['logo'],{position:'fixed',y:0});
            this.logoLocked=1;
          }
          
      }

      
      let grey = (1 - ((progress - 0.95) / 0.05)) * 255;
      grey = Math.min(grey,255);
      grey = Math.round(grey);
      //console.log('here',grey,'rgb('+grey+','+grey+','+grey+')');

      this.setState({
        progress:progress,
        logoProgress : Math.min(1,progress * 2),
        color:(progress > 0.75) ? 'rgb('+grey+','+grey+','+grey+')' : 'white'
      });

  }

  doComplete = () => {
    this.complete = 1;
    this.setState({complete:1,logoProgress:1,progress:1,color:'black'});
    this.props.store.store('introComplete',1);


  }
  autoScroll = (duration) => {

    let _this = this;

    if(this.state.complete){

      //window.scrollTo(0,0);
      return;
    };

    window.TweenLite.to(window,duration,{scrollTo:{y:this.refs['intro'].offsetHeight,autoKill:false},ease:window.Power4.easeInOut,onComplete : function(){
      _this.doComplete();
    }});

  }

  componentDidMount = () => {

    let _this = this;

    if(this.props.auto){
    setTimeout(function(){
      
      if(!window.scrollY){
        _this.autoScroll(2);
      }

    },this.props.auto);
    }
    

  }

  activate = (ix) => {

    this.setState({navActive:ix});
  }

  render() {



    // Load the settings to get the manifest (JSON data to power the site)
    let settings = conf.get();
    let _this = this;


    return (
 
        <ViewportProgress start={0} useHeight={true} onProgress={(data)=>{this.update(data)}}>
          <div ref="intro" className={"Intro Intro--"+((this.state.complete) ? 'complete' : 'progress')} data-layout="fluid" onClick={() => {this.autoScroll(2)}}>
           
           <div className="Intro-logo-wrap" ref="logo">
              <div className="Intro-logo"  style={{color:this.state.color}}>
                <div className="Intro-transition Intro-transition--logo">
                <div ref="logotext" onClickOff={()=>this.props.store.toggle('SLIDESHOW',true)}>
                  <Link to={'/'} className="Link"><Project.Logo progress={this.state.logoProgress} /></Link>
                </div>
                </div>
              </div> 
            </div>
            <div className="Intro-nav">
              <div className="Intro-transition Intro-transition--nav">
              <Data require={['structures']}>
      {({structures,ENTRY,entries,assets})=>{
          return (
              <ul className="List List--nav">
                                {structures.main.map(((item,ix)=>(
                                    <li key={'nav_'+ix}><Link navlink autotarget matchUri={item.uri} to={((item.children.length) ? item.children[0].uri : item.uri)}>{item.title}</Link>
                                      <ul>
                                      {item.children.map((child,cx)=>(
                                         <li key={'nav_child_'+cx}>
                                            <Link navlink autotarget to={child.uri}>{child.title}</Link>
                                         </li>
                                      ))}
                                      </ul>
                                    </li>
                                )))}
                              </ul>
                )}}
                </Data>
                </div>
            </div>
            <div className="Intro-media" ref="media">
              <Gallery fluid items={this.images.limit(5)} delay={3000} autoplay getItem={(item) => (<Media className="Image--intro" fluid id={item.id} size={'large'} />)} />
            </div>
            <div className="Intro-background" ref="background" />
            <div className="Intro-band"  ref="band" />  
           
            <div className="Intro-spacer Intro-spacer--logo" ref="logoSpacer" />  
            <div className="Intro-spacer Intro-spacer--media" ref="mediaSpacer" />  
          </div>
        </ViewportProgress>
    );
  }
}



export default withStore(Intro);
