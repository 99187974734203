import React, { Component } from "react";

/*import {SiteModifier,Icon,Link,Base,Map,List,IsVisible,Media,Content,Grid} from '../../_core/components';*/

import Icon from "../../_core/components/Icon";
import Link from "../../_core/components/Link";
import Base from "../../_core/components/BaseComponent";
import Map from "../../_core/components/Map";
import List from "../../_core/components/List";
import IsVisible from "../../_core/components/IsVisible";
import Media from "../../_core/components/Media";
import Content from "../../_core/components/Content";
import Grid from "../../_core/components/Grid";
import Nl2br from "../../_core/components/Nl2br";
import SiteModifier from "../../_core/components/SiteModifier";

import { Entry, Asset } from "../../_core/models";
import { Each } from "../../_core/utils";

import "../../_core/helpers/String";

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

// Project
import * as Project from "../../components";

// Conf
import conf from "../../_core/_conf";

class Blocks extends Component {
  sections = [];
  componentDidMount = () => {};
  cleanUri = (uri) => {
    let settings = conf.get();
    return uri.replace(settings.CDN_HOSTNAME, "");
  };
  makeBlocks = () => {
    this.section = [];
    let blocks = this.props.blocks || [];

    if (!blocks.length) {
      this.sections = [];
      return;
    }

    // Get the background positions...
    if (blocks[0].type != "background")
      blocks.unshift({
        type: "background",
        style: "none",
      });

    let sections = [];
    let section = -1;

    blocks.forEach((block) => {
      if (block.type == "background") {
        section++;
        sections[section] = { ...block, blocks: [] };
      } else {
        sections[section].blocks.push(block);
      }
    });

    this.sections = sections;
  };
  getBlock = (origBlock, entry) => {
    let _this = this;
    let SETTINGS = conf.get();

    let block = { ...origBlock, type: origBlock.acf_fc_layout };
    switch (block.type) {
      case "divider":
        return (
          <div
            className={"Block Block--divider Block--" + block.size}
            data-type={block.type}
          />
        );

      case "blank":
        return (
          <div
            id={block.anchor_id}
            className={"Block Block--blank Block--" + block.size}
            data-type={block.type}
          />
        );

      case "embed":
        return (
          <div id={block.anchor_id} className={"Block Item-body Block--embed"}>
            <div
              dangerouslySetInnerHTML={{
                __html: `
                  <div>
                    <div style="padding:56.25% 0 0 0;position:relative;">
                      <iframe
                        src="https://player.vimeo.com/video/${block.vimeo_id}"
                        style="position:absolute;top:0;left:0;width:100%;height:100%;"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen></iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                  </div>
                `,
              }}></div>
          </div>
        );

      case "item":
        return (
          <Asset
            id={[block.image]}
            render={(asset) => {
              return (
                <div
                  id={block.anchor_id}
                  className={
                    "Block Block--item Block--" +
                    block.size +
                    " Block--orientation-" +
                    asset.orientation
                  }
                  data-type={block.type}>
                  <div className="Block-content">
                    {(() => {
                      let link = block.link || block.customLink;

                      if (link) {
                        return (
                          <Link
                            className={"Item Item--block Item--" + block.aspect}
                            to={link}>
                            <div id={block.anchor_id} className="Item-media">
                              <Media asset={asset} fluid={false} />
                            </div>
                            <div className="Item-body">
                              <div className="Item-title">
                                {block.title.decodeHTML()}
                              </div>
                              <div className="Item-subtitle">
                                {block.subtitle.decodeHTML()}
                              </div>
                            </div>
                          </Link>
                        );
                      } else {
                        return (
                          <div
                            id={block.anchor_id}
                            className={
                              "Item Item--block Item--" + block.aspect
                            }>
                            <div className="Item-media">
                              <Media id={block.image} fluid={false} />
                            </div>
                            <div className="Item-body">
                              <div className="Item-title">
                                {block.title.decodeHTML()}
                              </div>
                              <div className="Item-subtitle">
                                {block.subtitle.decodeHTML()}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              );
            }}
          />
        );

      case "image":
        let images = block.images || [];
        return images.map((image) => (
          <Asset
            id={[image.image]}
            render={(asset) => {
              return (
                <div
                  key={image.image}
                  id={block.anchor_id}
                  className={
                    "Block Block--image Block--" +
                    image.size +
                    " Block--orientation-" +
                    asset.orientation
                  }
                  data-type={block.type}>
                  <div className="Block-content">
                    <div className={"Item Item--block Item--" + image.aspect}>
                      <div className="Item-media">
                        <Media asset={asset} fluid={false} />
                      </div>
                      {(() => {
                        if (image.caption) {
                          return (
                            <div className="Item-body">
                              <div className="Item-caption">
                                {image.caption}
                              </div>
                            </div>
                          );
                        }
                      })()}
                    </div>
                  </div>
                </div>
              );
            }}
          />
        ));

      case "text":
        let style = block.style || "default";
        return (
          <div
            id={block.anchor_id || ""}
            className={
              "Block Block--text Block--" +
              block.size +
              "  Block--" +
              block.style
            }
            data-type={block.type}>
            <div className="Block-content">
              {(() => {
                if (block.heading) {
                  return (
                    <div id={block.anchor_id} className="Block-heading">
                      <Nl2br text={block.heading} />
                    </div>
                  );
                }
              })()}

              {/* Text / Article */}
              <Grid modifiers={["article"]}>
                <div className="Grid-item">
                  <Content
                    modifier={"article Content--" + style}
                    html={block.text}
                  />
                </div>
                {(() => {
                  if (block.caption && !block.caption.match("data-mce-bogus")) {
                    return (
                      <div
                        id={block.anchor_id}
                        className="Grid-item Grid-item--caption">
                        <Content modifier="caption" html={block.caption} />
                      </div>
                    );
                  }
                })()}
              </Grid>

              <Grid modifiers={["links"]}>
                <Each
                  items={block.links}
                  render={(item) => {
                    let uri =
                      item.customLink && item.customLink.length
                        ? item.customLink
                        : this.cleanUri(item.linkTo);
                    return (
                      <div id={block.anchor_id} className="Grid-item">
                        <Link
                          to={uri}
                          className={
                            "Link Link--callToAction Link--" + block.style
                          }
                          deactivate={"#nav"}>
                          <span className="Link-body">{item.callToAction}</span>
                        </Link>
                      </div>
                    );
                  }}
                />
              </Grid>
            </div>
          </div>
        );

      default:
        return (
          <div
            data-type={block.type}
            id={block.anchor_id}
            className={
              "Block Block--" +
              block.type +
              " Block--unknown Block--" +
              block.size
            }>
            {JSON.stringify(block)}
          </div>
        );
    }
  };
  render() {
    this.makeBlocks();
    //console.log('blocks',this.sections);

    let { sections } = this;
    let modifiers = this.props.modifiers || [];
    let entry = this.props.entry || false;

    return (
      <Base family="Blocks" modifiers={modifiers}>
        {sections.map((section, ix) => {
          return (
            <div
              key={entry.id + "_" + ix}
              className={"Blocks-section Blocks-section--" + section.style}>
              {section.blocks.map((block) => {
                return this.getBlock(block, entry);
              })}
            </div>
          );
        })}
      </Base>
    );
  }
}

function Block(block) {}

export default Blocks;
