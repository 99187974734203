import React, { Component } from 'react';

//import {PageElement,Template,SiteModifier,Content} from '../_core/components';

import PageElement from '../_core/components/PageElement';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import SiteModifier from '../_core/components/SiteModifier';

// Models
import {Asset,Entry,Data,SetData} from '../_core/models';

// Project
import * as Project from '../components';



class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;
		if(!entry) return null;

		let blocks = entry.blocks || [];

		console.log('ENTRY',entry);

		let isHome = entry.uri == '/';
	
		return (
	
				<Template modifiers={[entry.type,entry.template,'blocks']} title={entry.title}>
				
				{/* Reset styles */}
				<SiteModifier auto demodifier={'scrolled'} />
				{(()=>{

	                if(isHome){
	                  return (<SiteModifier auto modifier={'home,projects'} />);
	                }else{
	                	return (<SiteModifier auto modifier={'projects'} demodifier={'home'} />);
	                }

	              })()}

				{/* Template */}
		
					{/* Title */}
					<div className="Template-head">
						<div className="Container">
							<h1>{ entry.title }</h1>
						</div>
					</div>
					

					{/* Blocks */}
					<div className="Template-blocks">
						<div className="Container">
							<Project.Blocks blocks={[...blocks]} entry={entry} modifiers={['debugoff']} />
						</div>
					</div>
				
				</Template>
		)

	}
}

export default Default;
