import React, { Component } from "react";

//import {PageElement,Template,SiteModifier,Content} from '../_core/components';

import PageElement from "../_core/components/PageElement";
import Template from "../_core/components/Template";
import Content from "../_core/components/Content";
import SiteModifier from "../_core/components/SiteModifier";
import Image from "../_core/components/Image";
import FadeIn from "../_core/components/FadeIn";
import Html from "../_core/components/Html";

// Models
import { Asset, Entry, Data, SetData } from "../_core/models";

// Project
import * as Project from "../components";
import { withStore } from "../_core/utils";

class Default extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  /*

		RENDER

	*/

  render() {
    let { entry } = this.props;
    if (!entry) return null;

    let blocks = entry.blocks || [];

    //console.log('ENTRY',entry);

    let isHome = entry.uri == "/";

    return (
      <Template modifiers={[entry.type, entry.template]} title={entry.title}>
        <FadeIn>
          {/* Reset styles */}
          <SiteModifier auto demodifier={"scrolled,gridscrolled,caption"} />
          {(() => {
            if (isHome) {
              return <SiteModifier auto modifier={"home,projects"} />;
            } else {
              return <SiteModifier auto demodifier={"home,projects"} />;
            }
          })()}

          {/* Template */}

          {/* Title */}
          <div className="Template-head">
            <div className="Container">
              <h1>{entry.title}</h1>
            </div>
          </div>

          <div className="Template-intro">
            <Content modifier="intro">
              <Html html={entry.introText1} />
              <div className="Template-more">
                <Html html={entry.introText2} />
              </div>
              <SiteModifier auto modifier={"more"} />
            </Content>
          </div>

          {/* Body */}
          {(() => {
            if (entry.content && entry.content.trim() != "<p></p>") {
              return (
                <div className="Template-body">
                  <Content modifier={"basic"} html={entry.content} />
                </div>
              );
            }
          })()}

          {/*<div className="Template-media">
						<Image src="/ui/img/placeholder/profile.jpg" fluid={false} ratio={6/4} />
					</div>*/}

          {/* Blocks */}
          <div className="Template-blocks">
            <div className="Container">
              <Project.Blocks
                blocks={[...blocks]}
                entry={entry}
                modifiers={["debuff"]}
              />
            </div>
          </div>
        </FadeIn>
      </Template>
    );
  }
}

export default withStore(Default);
