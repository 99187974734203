import React, { Component } from 'react';

//import {PageElement,Template,SiteModifier,Content} from '../_core/components';

import PageElement from '../_core/components/PageElement';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import SiteModifier from '../_core/components/SiteModifier';
import ScrollTo from '../_core/components/ScrollTo';

// Models
import {Asset,Entry,Data,SetData} from '../_core/models';

// Project
import * as Project from '../components';



class Default extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;
		if(!entry) return null;

		let blocks = entry.blocks || [];

		console.log('ENTRY',entry);

		let isHome = entry.uri == '/';
	
		return (
	
				<Template modifiers={[entry.type,entry.template,'blocks']} title={entry.title}>

				 <ScrollTo pos={0} key={entry.id} duration={0} />
				
				{/* Reset styles */}
				<SiteModifier auto demodifier={'scrolled'} />
				{(()=>{

	                if(isHome){
	                  return (<SiteModifier auto modifier={'home,projects'} />);
	                }else{
	                	return (<SiteModifier auto modifier={'projects'} demodifier={'home'} />);
	                }

	              })()}

				{/* Template */}
		
					{/* Title */}
					<div className="Template-head">
						<div className="Container">
							<h1>{ entry.title }</h1>
						</div>
					</div>

					{/* Intro */}
					<div className="Template-intro">
						<div className="Grid Grid--project">
							<div><Content modifier="intro" html={entry.content} /></div>
							<div>
							<dl className="List List--definition">
			                  <li>
			                    <dt>Location</dt>
			                    <dd>{entry.projectLocation}, {entry.projectCity}</dd>
			                  </li>
			                  <li>
			                    <dt>Size</dt>
			                    <dd>{entry.projectSize}</dd>
			                  </li>
			                  {(()=>{

			                  	if(entry.projectClient){
			                  		return (
			                  		<li>
					                    <dt>Client</dt>
					                    <dd>{entry.projectClient}</dd>
					                </li>
			                  		)
			                  	}

			                  })()}
			                  {(()=>{

			                  	if(entry.projectBudget){
			                  		return (
			                  		<li>
					                    <dt>Budget</dt>
					                    <dd>{entry.projectBudget}</dd>
					                </li>
			                  		)
			                  	}

			                  })()}
			                  
			                  {entry.projectDetails && entry.projectDetails.map(
			                  	(d)=>(
			                  		<li>
			                    		<dt>{d.label}</dt>
			                    		<dd>{d.detail}</dd>
			                  		</li>
			                  	)
			                  )}
			                </dl>
			               </div>
						</div>
					</div>
					

					{/* Blocks */}
					<div className="Template-blocks">
						<div className="Container">
							<Project.Blocks blocks={[...blocks]} entry={entry} modifiers={['debugoff']} />
						</div>
					</div>
				
				</Template>
		)

	}
}

export default Default;
