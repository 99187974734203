import React, { Component } from 'react';

//import {PageElement,Template,SiteModifier,Content} from '../_core/components';

import PageElement from '../_core/components/PageElement';
import Template from '../_core/components/Template';
import Content from '../_core/components/Content';
import SiteModifier from '../_core/components/SiteModifier';

// Models
import {Asset,Entry,Data,SetData} from '../_core/models';

import {withStore,withSite} from '../_core/utils';

// Project
import * as Project from '../components';



class Default extends Component {

	projects = [];

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentWillUnmount() {

	}

	componentWillReceiveProps = (props) => {
		return;
		if(!props.entry) return;
		let projects = props.entry.relatedProjects;
		if(!projects) projects = [];

		if(JSON.stringify(projects) != JSON.stringify(this.projects)){
			this.setProjects(projects);
		}

	}

	setProjects = (projects) => {
		
		this.projects = projects;
		//this.props.store.store('projectsFilter',projects);

	}

	/*

		RENDER

	*/

	render() {

		let {entry} = this.props;
		if(!entry) return null;

		let blocks = entry.projects || [];
		let entries = this.props.store.get('entries');

		if(!blocks.length){

			let projects = entry.relatedProjects || entries.filter(e=>(e.type == 'projects')).map(e => (e.id));

			blocks = projects.map(p=>(entries.find(e=>(e.id == p)))).map(p=>({
				'acf_fc_layout' : 'item',
				'size' : 'onethird',
				'aspect' : 'landscape',
				'image' : p.projectThumbnail,
				'title' : p.title,
				'subtitle' : p.projectLocation,
				'link' : p.uri
			}))
		}

		let isHome = entry.uri == '/';

	
		return (
			
				<Template modifiers={[entry.type,entry.template,'blocks']} title={entry.title}>

				
				
				{/* Reset styles */}
				<SiteModifier auto demodifier={'scrolled'} />
				{(()=>{

	                if(isHome){
	                  return (<SiteModifier auto modifier={'home,projects'} />);
	                }else{
	                	return (<SiteModifier auto modifier={'projects'} demodifier={'home'} />);
	                }

	              })()}

				{/* Template */}
		
					{/* Title */}
					<div className="Template-head">
						<div className="Container">
							<h1>{ entry.title }</h1>
						</div>
					</div>

					{/* Intro */}
					<div className="Template-intro">
						<div className="Grid Grid--project">
							<div><Content modifier="intro" html={entry.content} /></div>
							<div>
							
			               </div>
						</div>
					</div>
					

					{/* Blocks */}
					<div className="Template-blocks">
						<div className="Container">
							<Project.Blocks blocks={[...blocks]} entry={entry} modifiers={['projects']} />
						</div>
					</div>
				
				</Template>
			
		)

	}
}

export default withStore(Default);
