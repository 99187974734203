import React, { Component } from "react";

// Conf
import conf from "../_core/_conf";

// Core
import Loader from "../_core/components/Loader";
import Media from "../_core/components/Media";
import ViewportProgress from "../_core/components/ViewportProgress";
import JumpLink from "../_core/components/JumpLink";
import ScrollModifier from "../_core/components/ScrollModifier";
import Image from "../_core/components/Image";
import Link from "../_core/components/Link";
import PageElement from "../_core/components/PageElement";
import Site from "../_core/components/Site";
import ScrollTo from "../_core/components/ScrollTo";
import Burger from "../_core/components/Burger";
import Icon from "../_core/components/Icon";
import Content from "../_core/components/Content";
import Transition from "../_core/components/Transition";
import SiteModifier from "../_core/components/SiteModifier";
import NormalizedScroller from "../_core/components/NormalizedScroller";
import MousePos from "../_core/components/MousePos";
import FadeIn from "../_core/components/FadeIn";

import { Entry, Asset, Data } from "../_core/models";
import { Template, Modal } from "../_core/controllers";
import { withStore, withSite } from "../_core/utils";

import { withRouter } from "react-router-dom";

// Project
import * as Project from "../components";

// Profile template
import Profile from "./profile";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = { scrollDelta: 0, scrollUpdates: 0 };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  /*

		RENDER

	*/

  render() {
    // Load the settings to get the manifest (JSON data to power the site)
    let settings = conf.get();
    let _this = this;

    let HISTORY = this.props.history;

    return (
      <Data>
        {({ structures, ENTRY, entries, assets, caption, introComplete }) => {
          return (
            <div className="Site-page" id="page">
              <ScrollModifier reverse={false} />
              <SiteModifier auto modifier={"preloaded"} />

              {/*<ScrollTo pos={0} key={window.location.href} duration={0} />*/}

              {(() => {
                //return null;
                if (introComplete) {
                  return [
                    <ScrollTo pos={0} key={"intro"} duration={0} />,
                    <SiteModifier modifier="introComplete" auto delay={250} />,
                  ];
                } else {
                  return null;
                }
              })()}

              {/* Site Content */}
              <div className="Site-content">
                <div className="Site-template">
                  <ScrollTo
                    useHistory={true}
                    ignore={({ pathname }, history) => {
                      return pathname.match("team") && window.location.hash;
                    }}
                  />
                  <Template />
                </div>
              </div>
              {/* End Site Content */}

              <div className="Site-backToTop">
                <JumpLink to={0}>
                  <span>Back to top</span>
                </JumpLink>
              </div>

              {/* Site Foot */}
              <div className="Site-foot" id="footer">
                <div className="Site-foot-contact">
                  <div data-grid-of="2">
                    <div>
                      <Content modifier="footer">
                        <p>
                          76 Charlotte Street,
                          <br />
                          London,{" "}
                          <a href="https://goo.gl/maps/7mys7KADdnveQPip9">
                            W1T 4QS
                          </a>
                        </p>
                      </Content>
                    </div>

                    <div>
                      <Content modifier="footer">
                        <p>T: +44(0)20 7435 1144</p>
                        <p>
                          E:{" "}
                          <a href="mailto:info@dmfk.co.uk">info@dmfk.co.uk</a>
                        </p>
                      </Content>
                    </div>
                  </div>
                </div>
                <div className="Site-foot-social">
                  <ul className="List List--icons">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/DMFK-Architects-613213678705696/?fref=ts">
                        <Icon glyph type="facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/dmfkarchitects/?hl=en">
                        <Icon glyph type="instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/dmfk">
                        <Icon glyph type="linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="Site-foot-copy">
                  Copyright &copy; {new Date().getFullYear()}{" "}
                  {conf.get().SITENAME}
                </div>
              </div>
              {/* End Site Foot */}
            </div>
          );
        }}
      </Data>
    );
  }
}

export default withRouter(withStore(Layout));
