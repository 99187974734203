import GoogleAnalytics from "react-ga";
const MAPSTYLES = require("./json/map.json");
const IS_PRODUCTION = window.location.hostname == "dmfk.co.uk";
const CDN_HOSTNAME = "//dmfk.webcdn.network";
const hostName = IS_PRODUCTION ? CDN_HOSTNAME : "";
const d = new Date();
const CACHEBUSTER = IS_PRODUCTION
  ? "?nc=" + d.getFullYear() + d.getMonth() + d.getDate() + d.getHours()
  : "?nc=" + d.getTime();

const WP_mediaTransformer = (media, store) => {
  let assets = media
    .filter((item) => !!item)
    .map((item) => {
      let i = { ...item };
      let media = i.media_details;

      if (!media) return {};

      if (!media.width) media.width = 0;
      if (!media.height) media.height = 0;

      let orientation = media.width > media.height ? "landscape" : "portrait";

      let a = {
        id: i.id,
        title: i.title.rendered,
        filename: i.source_url.split("/").pop(),
        url: i.source_url,
        width: media.width,
        height: media.height,
        orientation: orientation,
        sizes: {
          local: i.source_url,
          original: i.source_url,
        },
        imageAnimation: [],
        animationSpeed: 500,
        inlineVideo: false,
        videoUrl: "",
        alt: i.alt_text,
        raw: true, // Add a raw img tag to teh output for downloading
      };

      if (media.sizes && Object.keys(media.sizes).length) {
        a.sizes = {
          local: media.sizes.full.source_url,
          large: media.sizes.full.source_url,
          medium: media.sizes.hasOwnProperty("large")
            ? media.sizes.large.source_url
            : media.sizes.full.source_url,
          small: media.sizes.hasOwnProperty("medium_large")
            ? media.sizes.medium_large.source_url
            : media.sizes.full.source_url,
          original: media.sizes.full.source_url,
        };
      }

      if (i.acf && i.acf.imageAnimation && i.acf.imageAnimation.images.length) {
        a.imageAnimation = i.acf.imageAnimation.images.map((i) => i.image);
        if (
          i.acf.imageAnimation.animationSpeed &&
          i.acf.imageAnimation.animationSpeed != ""
        )
          a.animationSpeed = parseInt(i.acf.animationSpeed);
      }

      if (i.acf && i.acf.videoUrl && i.acf.videoUrl.length) {
        a.videoUrl = i.acf.videoUrl;
        a.inlineVideo = i.acf.inlineVideo;
      }

      return a;
    });

  return assets;
};

const WP_pagesTransformer = (pages, store) => {
  // If thre is an __ENTRY variable already in the window, merge its data

  if (window.__ENTRY) {
    let WINDOW_ENTRY = { ...window.__ENTRY };
    let existing = pages.find((p) => p.id == window.__ENTRY.ID);

    WINDOW_ENTRY = {
      ...WINDOW_ENTRY,
      title: { rendered: WINDOW_ENTRY.page_title },
      content: { rendered: WINDOW_ENTRY.post_content },
    };

    if (existing) {
      pages[pages.indexOf(existing)] = { ...existing, ...WINDOW_ENTRY };
    } else {
      // Make a new entry...
      pages.push({
        ...WINDOW_ENTRY,
        slug: "__ENTRY",
        uri: "/__ENTRY/",
        link: "/__ENTRY/",
      });
    }
  }

  let entries = pages.map((item) => {
    let i = { ...item };
    i.url = i.link.replace(/(http:|https:)?\/\/[^\/]+/, "");
    i.uri = i.url;

    if (i.id == store.frontpage.id) {
      i.url = "/";
      i.uri = "/";
    }

    i.title = i.title.rendered;
    //i.template = 'default';
    i.content = i.content.rendered;
    i.postDate = i.date;

    if (i.template) {
      i.template = i.template.replace("template-", "").replace(".php", "");
    } else {
      //i.template = i.type;
    }

    if (i.acf) {
      i = Object.assign(i, i.acf);
      delete i.acf;
    }

    if (i.blocks) {
      i.blocks = i.blocks.map((block) => ({
        ...block,
        type: block.acf_fc_layout,
      }));
    }

    if (i.ticker) {
      i.ticker = i.ticker.map((item) => {
        let copy = { ...item };

        if (item.linkTo || item.customLink) {
          copy.uri = item.customLink
            ? item.customLink
            : item.linkTo.replace(CDN_HOSTNAME, "");
        }

        return copy;
      });
    }

    return i;
  });

  //console.log('ENTRIES',entries);
  return store.hasOwnProperty("posts") ? entries.concat(store.posts) : entries;
};

const WP_menuTransformer = (menu) => {
  return menu.map((item) => {
    let url =
      item.type == "custom"
        ? item.url
        : item.url.replace(/(http:|https:)?\/\/[^\/]+/, "");
    let i = {
      id: item.ID,
      slug: item.title.replace(/\s+/g, "-").toLowerCase(),
      title: item.title,
      uri: url.replace(CDN_HOSTNAME, ""),
      children: WP_menuTransformer(item.children),
    };

    return i;
  });
};

const WP_structuresTransformer = (structures) => {
  let transformed = {};
  Object.keys(structures).forEach((k) => {
    transformed[k] = WP_menuTransformer(structures[k]);
  });

  if (!transformed.main) transformed.main = [];
  if (!transformed.footer) transformed.footer = [];
  if (!transformed.secondary) transformed.secondary = [];
  if (!transformed.footer) transformed.footer = [];
  if (!transformed.shortcuts) transformed.shortcuts = [];
  if (!transformed.header) transformed.header = [];
  if (!transformed.locations) transformed.locations = [];

  return transformed;
};

let host = "";

const settings = {
  SITENAME: "dMFK",
  CDN_HOSTNAME: CDN_HOSTNAME,
  MANIFEST: [
    {
      name: "frontpage",
      src: host + "/data.json" + CACHEBUSTER,
      type: "json",
      subset: "frontpage",
    },
    {
      name: "entries",
      src: host + "/entries.json" + CACHEBUSTER,
      type: "json",
      transformer: WP_pagesTransformer,
    },
    {
      name: "assets",
      src: host + "/assets.json" + CACHEBUSTER,
      type: "json",
      transformer: WP_mediaTransformer,
    },
    {
      name: "structures",
      src: host + "/data.json" + CACHEBUSTER,
      type: "json",
      subset: "structures",
      transformer: WP_structuresTransformer,
    },
  ],
  GOOGLE_MAPS_API_KEY: "AIzaSyDVrWNmCqOUcpZhpAR3SNJX9pw-21NFabY",
  MAPSTYLES: MAPSTYLES,
  GA_ID: "UA-122678876-11",
  GTM_ID: "GTM-XXXX", // Tag Manger
};

/*
	Init GA
*/

GoogleAnalytics.initialize({
  trackingId: settings.GA_ID,
  debug: true,
});

export default settings;
