import React, { Component, Children } from "react"
import PropTypes from 'prop-types'; 

import "../helpers/Array";

let DATA = {};

class Store extends Component {

keysChanged = [];
state = {data:{},keysChanged:[]};
empty = true;

constructor(props){

  super(props);
}


  static propTypes = {
    store:PropTypes.object
  }
  // you must specify what you’re adding to the context
  static childContextTypes = {
    store:PropTypes.object
  }



store = (label,item,publish=true) => {
      
      //console.log('store',label,'publish',publish);
      DATA = {...DATA};
      DATA[label] = item;
      //this.setState({data:DATA});

      if(this.keysChanged.indexOf(label) == -1) this.keysChanged.push(label);
  
      if(publish) this.publish();
  }

  toggle = (label,item,publish=true) => {
      
      //console.log('store',label,'publish',publish);
      DATA = {...DATA};
      if(DATA[label] == item){
          this.unstore(label,item,publish);
      }else{
          this.store(label,item,publish);
      }
     
  }

  unstore = (label,item,publish=true) => {
      
      //console.log('store',label,'publish',publish);
      DATA = {...DATA};
      if(!DATA.hasOwnProperty(label)) return;

      delete DATA[label];
      //this.setState({data:DATA});


      if(this.keysChanged.indexOf(label) == -1) this.keysChanged.push(label);
      if(publish) this.publish();
  }

  merge = (label,item,publish=true) => {
      
      //console.log('store',label,'publish',publish);
      DATA = {...DATA};
      let cur = DATA[label];
   
      let merge = [];
      if(typeof cur != 'undefined'){
        if(!Array.isArray(cur)) merge = [cur];
        else merge = cur;
        merge = [...merge,item];
      }else{

        merge = [item];

      }
      DATA[label] = merge;

   
      if(this.keysChanged.indexOf(label) == -1) this.keysChanged.push(label);
      if(publish) this.publish();
  }

  unmerge = (label,item,publish=true) => {
      
      //console.log('store',label,'publish',publish);
      DATA = {...DATA};
     if(!DATA.hasOwnProperty(label)) return;
     let _this = this;
      DATA[label] = DATA[label].filter((i) => (!_this.objCompare(i,item)));
      if(!DATA[label].length) delete DATA[label];
      
      //this.setState({data:DATA});

      if(this.keysChanged.indexOf(label) == -1) this.keysChanged.push(label);

      if(publish) this.publish();
  }

  doPublish = 0;
  publishing = 0;
  publish = () => {
      
      //this.doPublish = 1;
      this.runPublish();

  }

  get = (label,data) => {
     
      if(DATA.hasOwnProperty(label)) return DATA[label];

  }

  objCompare = (a,b) => {

    return (JSON.stringify(a) === JSON.stringify(b));

  }

  equals = (label,data) => {

      if(!DATA.hasOwnProperty(label)) return false;
      let val = DATA[label];

      let match  = (this.objCompare(val,data));
      return match;

  }

  contains = (label,data) => {

      if(!DATA.hasOwnProperty(label)) return false;
      let val = DATA[label];



      if(!Array.isArray(val)) return this.objCompare(val,data);

      let matches = 0;
      val.forEach((i)=>{

          if(this.objCompare(i,data)) matches++;

      })
      return matches > 0;

  }


componentDidUpdate() {

    //this.keysChanged = [];
    //this.doPublish = 0;

    if(this.keysChanged.length){
      // Commented out the state change as was causing race conditions when datarouter items weere set at the same time as other data items
      //this.setState({keysChanged:[]});
      //this.keysChanged = [];
    }

    this.publishing = 0;


}
updateEmpty = () => {
  // Update whether store is empty
    function isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    this.empty = isEmpty(DATA);
}

isEmpty = () => {
  return this.empty;
}

ticker = 0;
check = () => {

  if(this.doPublish && !this.publishing){
    //console.log('DOING PUBLISH',this.keysChanged);
    this.runPublish();
  }
  
  //this.ticker = requestAnimationFrame(this.check);

      //console.log('DATA PUBLISH',this.keysChanged);
      

      //PubSub.publish('store',DATA);
        //this.keysChanged = [];

        //console.log('DATA PUBLISH',keysChanged);
       //this.setState({data:DATA,keysChanged:keysChanged});

}

runPublish = () => {

  
  if(!this._ismounted || this.publishing) return;

  //console.log('SET STATE');
 
  let keysChanged = [...this.keysChanged];
  this.doPublish = 0;
  this.publishing = 1;
  this.keysChanged = [];
  this.updateEmpty();
  this.setState({data:DATA,keysChanged:keysChanged});




}
_ismounted = false;
componentDidMount() {

  var _this = this;
  this._ismounted = true;
  // Expose store API to window
  global.store = this;
  //this.ticker = setInterval(this.check,1000/30); // 30 FPS
  //this.ticker = requestAnimationFrame(this.check);

}

componentWillUnmount() {
   this._ismounted = false;
   if(this.ticker) cancelAnimationFrame(this.ticker);
   this.ticker = 0;
}

  // Here's where the values are exported to any components that have registered an interest in the context
  getChildContext() {
    
   return { store:this}
  }


  render() {

    return Children.only(this.props.children)
    
  }
}

/*

withStore()
- Add site as props to any component exported wrapped in withSite()
- export default Component = withSite(Component);

*/

export const withStore = (ComponentToWrap) => {
  return class StoreComponent extends Component {
    // let’s define what’s needed from the `context`
    static contextTypes = {
      store: PropTypes.object
    }
    render() {
      
      const { store } = this.context;
      const data = store.state.data;
      const keysChanged = store.state.keysChanged;

      //console.log('STORE HOC UPDATE',keysChanged, ComponentToWrap.name);
      // what we do is basically rendering `ComponentToWrap`
      // with an added `theme` prop, like a hook
      return (
        <ComponentToWrap {...this.props} dataChanged={keysChanged} store={store} data={data} />
      )
    }
  }
}

// Note this is wrapped in the withSite() function, which adds the site as props to the component automatically
export const Data = withStore(
  class Data extends Component {
      
      render() {

    let store= this.props.store;
    let data = store.state.data;

    if(!data) return null;

    let rendered = this.props.children(data);

        return rendered;
    

  }

  });





export default Store;